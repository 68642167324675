import React, { useState } from "react"
import Community from "../component/home/Community";
import Leaderboard from "../component/home/Leaderboard";
import Giveaways from "../component/home/Giveaways";

const Home = () => {

    const [activeTab, setActiveTab] = useState('community');

    // Static Data
    const tabs = [{name: 'Community Points', id: 'community'}, {name: 'Leaderboard', id: 'leaderboard'}, { name: 'Giveaways', id: 'giveaways'}]
    const activeTabClass = "tab-btn focus:outline-none p-2 md:px-10 md:text-xl text-white font-bold border border-white rounded-2xl active-tab";
    const tabClass = "tab-btn focus:outline-none p-2 md:px-10 md:text-xl text-white font-bold border border-white rounded-2xl";

    return (
        <>
        <section className="py-5 p-5">
            <div id="tabs" className="flex justify-center items-center space-x-2">
                {tabs.map( item => 
                    <button 
                        key={item.id}
                        onClick={()=> setActiveTab(item.id)} 
                        className={item.id == activeTab ? activeTabClass : tabClass} 
                    >
                    {item.name}
                    </button>
                )}
            </div>
        </section>
        <div className="w-auto lg:max-w-7xl mx-auto">
        {
            activeTab === 'community' ? <Community /> :
            activeTab === 'leaderboard' ? <Leaderboard /> : 
            activeTab === 'giveaways' ? <Giveaways /> :
            ''
        }
        </div>
        </>
    )

}

export default Home