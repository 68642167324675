import React from 'react'
import Api from '../../api/Api';
// Images
import Img_frame1 from '../../assets/public/frame1.png';
import Img_frame13 from '../../assets/public/Frame13.png';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RunningEvent from './RunningEvent';
import PastEvent from './PastEvent';
const Giveaways = () => {
  const authData = useSelector( (state) =>  state.auth );
  
  const [activeTab, setActiveTab] = useState('running');

  // Static Data
  const tabs = [{name: 'Running Event', id: 'running'}, {name: 'Past Event', id: 'past'}]
  const activeTabClass = "tab-btn focus:outline-none p-3 md:px-10 md:text-xl text-white font-bold border border-white rounded-2xl active-tab";
  const tabClass = "tab-btn focus:outline-none p-3 md:px-10 md:text-xl text-white font-bold border border-white rounded-2xl";

  return (
    <>
    <div data-id="3" className="contents-data transition-all">
      <div className="py-5 px-5 space-y-10">
        <div className="relative flex justify-center items-center">
          <img src={Img_frame13} alt="" srcset="" />
          <div className="absolute text-white text-2xl md:text-4xl lg:text-7xl flex justify-between items-center w-full px-5">
            Tickets<span>{ authData?.wallet?.tickets}</span> 
          </div>
        </div>
        <section className="py-5 p-5">
            <div id="tabs" className="flex justify-center items-center space-x-2">
                {tabs.map( item => 
                    <button 
                        key={item.id}
                        onClick={()=> setActiveTab(item.id)} 
                        className={item.id == activeTab ? activeTabClass : tabClass} 
                    >
                    {item.name}
                    </button>
                )}
            </div>
        </section>
        {
            activeTab === 'running' ? <RunningEvent /> :
            activeTab === 'past' ? <PastEvent /> :
            ''
        }
    </div>
    </div>
    </>
  )
}

export default Giveaways