import React from "react";
import { useSelector } from "react-redux";
import { deleteAllCookies } from "../helper/Helper";
import Button from 'react-bootstrap/Button';
import Api from "../api/Api";

// Images
import Img_cross from '../assets/public/cross.png'
import Img_tick from '../assets/public/tick.png'
import Img_halfMoon from '../assets/public/halfMoon.png'
import Img_moon from '../assets/public/moon.png'

const Profile = () => {
    const authData = useSelector( (state) =>  state.auth );

    const handleLogout = () => {
        // Api.logout()
        window.location.replace(`${process.env.REACT_APP_DEFAULT_LOCAL_API_URL}/auth/logout`);
    }

    return(
        <>
        <div className="flex justify-center flex-col gap-20 max-w-xxl mx-auto lg:px-8 py-10">
            <div
            className="bg-white  px-6 py-12 pb-24 h-auto rounded-[32px] mx-auto sm:mx-auto sm:w-[100%] max-sm:max-w-[100%] md:max-w-xl md:w-full relative"
            style={{width:'100%'}}>

            <div className="absolute -left-[182px]  top-16 max-lg:hidden">
                <img src={Img_halfMoon} alt=""/>
            </div>

            <div>
                <h1 className="text-[40px] font-extrabold leading-none text-center text-[#6141A6]">Profile</h1>
                <h1 className="text-2xl font-extrabold leading-none text-center text-[#6141A6] pt-8">Account
                Information</h1>
            </div>

            <div className="pt-[25px] px-[70px] pb-[30px] max-sm:px-0">
                <table className="  w-full ">
                <tbody>
                    <tr>
                    <td className="text-[#6141A6] text-2xl text-start">Email</td>
                    <td className="text-[#6141A6] text-2xl text-end">xyz@gmail.com</td>
                    </tr>
                    <tr>
                    {/* <td className="text-[#6141A6] text-2xl text-start pt-5">Email Verified</td>
                    <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="flex justify-end">
                        <img src={Img_cross} alt="" />
                        <img src={Img_tick} alt="" />
                        </span>
                    </td> */}
                    </tr>
                </tbody>
                </table>
            </div>

            <div>
                <h1 className="text-2xl font-extrabold leading-none text-center text-[#6141A6]">Wallet
                Information</h1>
            
                <div className="pt-[30px] px-[70px] pb-[30px]  max-sm:px-0">
                <table className="  w-full ">
                    <tbody>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start">Connected Wallet</td>
                        <td className="text-[#6141A6] text-2xl text-end">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">In Game Coins</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[51px] rounded-2xl h-[40px]">
                        { authData?.wallet?.gameCoin }
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Tickets</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[54px] rounded-2xl h-[40px]">
                            { authData?.wallet?.tickets }
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Community Points</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[42px] rounded-2xl h-[40px]">
                            { authData?.wallet?.cp }
                        </span>
                        </td>
                    </tr> */}
                    
                    </tbody>
                </table>
                </div>
            </div>

            {/* <div>
                <h1 className="text-2xl font-extrabold leading-none text-center text-[#6141A6]">NFT Collection</h1>
            
                <div className="pt-[20px] px-[70px] pb-[30px]  max-sm:px-0">
                <table className="  w-full ">
                    <tbody>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start">Be A Membert</td>
                        <td className="text-[#6141A6] text-2xl text-end">
                        <span className="flex justify-end">
                            <img src={Img_cross} alt="" />
                            <img src={Img_tick} alt="" />
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Membership</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">PPFP</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Treasure Chest</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    
                    </tbody>
                </table>
                </div>
            </div> */}

            <div>
                <h1 className="text-2xl font-extrabold leading-none text-center text-[#6141A6]">Linked Accounts</h1>
            
                <div className="pt-[30px] px-[70px] pb-[30px]  max-sm:px-0">
                <table className="  w-full ">
                    <tbody>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start">X (Twitter)</td>
                        <td className="text-[#6141A6] text-2xl text-end">
                        <span className="bg-[#C2ACF2] py-2 text-[#6141A6] px-[15px] rounded-2xl">Connected</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Instagram</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Youtube</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-[#6141A6] text-2xl text-start pt-5">Astroon Fall</td>
                        <td className="text-[#6141A6] text-2xl text-end pt-5">
                        <span className="border-[#6141A6] border-2 py-[6px] text-[#6141A6] w-[144px] px-[44px] rounded-2xl h-[40px]">Soon</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div className="pt-[30px] px-[70px] pb-[30px]  max-sm:px-0 flex justify-between">
                <div>
                <div className="bg-[#6141A6] text-2xl py-2 text-white px-4 rounded-2xl cursor-pointer" onClick={()=>handleLogout()} >Sign Out</div>
                </div>

                <div>
                <p className="underline text-2xl text-[#6141A6]" style={{marginLeft:'20px'}}>Delete Account</p>
                </div>
            </div>

            <div className="absolute -right-[203px] bottom-80 max-lg:hidden">
                <img src={Img_moon} alt="" />
            </div>

            </div>
        </div>
        </>
    )
}

export default Profile;